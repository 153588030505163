import React from "react";
import a from "public/asset/image/NPCL.png";
import { toAbsoluteUrl } from "src/helpers";
import { Link } from "react-router-dom";
import LoginViaConsumerNo from "../Components/LoginViaConsumerNo";

const BLUE_BACKGROUND = toAbsoluteUrl("/asset/image/Blue_background.png");
const NPCL_SAURMITRA = toAbsoluteUrl("/asset/image/NPCL_Saur_mitra.png");
const NPCL_main = toAbsoluteUrl("/asset/image/NPCL_Main.png");
const RP_POWER = toAbsoluteUrl("/asset/image/RPSG-P2P.png");
const Npcl_logo = toAbsoluteUrl("/asset/image/NPCL.png");
const OCT_LOGO = toAbsoluteUrl("/asset/image/OCT_Logo_Horizontal.png");
const NPCL_MOSCOT_LEFT = toAbsoluteUrl("/asset/image/NPCL_Mascot_Left.png");
const NPCL_MOSCOT_RIGTH = toAbsoluteUrl("/asset/image/NPCL_Mascot_Right.png");
const LoginLanding = () => {
  return (
    <div
      className="w-full   bg-no-repeat bg-cover font-Roboto"
      style={{
        backgroundImage: `url(${BLUE_BACKGROUND})`,
      }}
    >
      <div className="w-full flex flex-col md:flex-row  md:h-[80%]">
      
        <div className="md:w-[50%]  lg:w-[60%] w-full space-y-1">
          <div className="w-full px-2 xsm:px-10 sm:px-16 md:px-4 lg:px-[70px] xl:px-44 text-xs sm:text-sm md:text-[16px] lg:text-xl font-medium font-Montserrat my-10 space-y-2">
            <div className="w-full pl-2">Presenting</div>
            <img src={NPCL_SAURMITRA} alt="npcl-saurmitra" />
            <div className="pl-2">
              A <b>Peer to Peer Energy Trading</b> Platform
            </div>
            <div className="pl-2">
              <span className="whitespace-nowrap">
                For more information or registration{" "}
              </span>{" "}
              <Link
                to={
                  "https://iwebapps.noidapower.com:8032/Authenticate/login.aspx"
                }
                target="_blank"
                className="text-text_primaryDarkBlue text-xs md:text-sm underline underline-offset-4 hover:underline-offset-8 whitespace-nowrap"
              >
                Click Here
              </Link>
            </div>
          </div>
          <div>

            <div className="w-full  hidden md:flex md:justify-between  relative">
              <div className="absolute xl:-mt-20 lg:-mt-16">
                <img src={NPCL_MOSCOT_LEFT} className="lg:w-[100px] h-[200px] xl:w-[120px] xl:h-[240px]" />
              </div>
              <div className="w-full flex justify-center">
              <img src={NPCL_main} alt="npcl-main" className="" />
              </div>
              <div className="absolute lg:-right-6 xl:right-3 xl:-mt-20 lg:-mt-16">
              <img src={NPCL_MOSCOT_RIGTH} className="lg:w-[100px] h-[200px] xl:w-[120px] xl:h-[240px]" />
            </div>
            </div>
           
          </div>
        </div>

        <div className="md:w-[50%] lg:w-[40%] w-full flex flex-col  h-full  justify-between items-center md:mt-8 lg:mt-12 px-2 md:px-0">
          <div className="flex space-x-20 items-center md:px-10 md:py-10 py-2">
            <img src={RP_POWER} alt="RP_POWER" className="w-24 xl:w-40" />
            <img src={Npcl_logo} alt="NPCL-LOGO" className="w-24 xl:w-40" />
          </div>

          <div className="sm:w-[80%] w-full border-2 border-border_primaryDarkBlue rounded-xl py-14 px-10 ">
            <div className="text-lg font-bold">Login</div>
            <div className="text-sm text-text_primaryDarkBlue  font-semibold mt-2">
              Consumer Number
            </div>
            <LoginViaConsumerNo />
          </div>
        </div>
      </div>

      <div className="w-full  space-x-2  h-[20%] bg-primaryDarkBlue/10 md:bg-none flex flex-col md:flex-row items-center md:mt-8 justify-center ">
        <div>In association with</div>
        <img src={OCT_LOGO} alt="oct_logo" className="" />
      </div>
    </div>
  );
};

export default LoginLanding;
